import { css } from '@emotion/react';
import { blue9, charcoal, gray5, gray7, red9, white } from '@fire/_color';
import { bold, fontFace } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const container = css`
  position: relative;
  width: 100%;
`;

export const contentWidth = css`
  margin: 0 auto;
  max-width: 326px;

  ${media.xs} {
    max-width: 422px;
  }
`;

export const headerContainer = css`
  background-color: ${white};
  width: 100%;
  margin-bottom: 55px;
`;

export const header = css`
  ${contentWidth}
  padding: 48px 0;

  ${media.xs} {
    padding: 68px 0;
  }
`;

export const headerTitle = css`
  font-size: 30px;

  ${media.xs} {
    font-size: 34px;
  }
`;

export const headerSubtitle = css`
  font-size: 20px;
  height: 29px;

  margin-top: 12px;
`;

export const formsContainer = css`
  ${contentWidth}
  margin-top: 38px;

  ${media.xs} {
    margin-top: 48px;
  }
`;

export const label = css`
  font-size: 20px;
  font-weight: ${bold};

  margin-top: 16px;
  margin-bottom: 16px;
  box-sizing: content-box;

  ${media.xs} {
    font-size: 18px;
    height: 20px;
  }
`;

export const labelSmall = css`
  ${label}
  font-size: 18px;
  margin-bottom: 8px;

  ${media.xs} {
    font-size: 16px;
    height: inherit;
  }
`;

export const createErrorAreaStyle = (mobileHeight, desktopHeight) => css`
  position: relative;
  width: 100%;
  height: ${mobileHeight}px;

  ${media.xs} {
    height: ${desktopHeight}px;
  }
`;

const commonInputStyle = css`
  ${fontFace};
  font-size: 16px;
  cursor: auto;
  resize: none;
  outline: none;
  width: 100%;
  padding: 10px 16px;

  &::placeholder {
    color: ${gray7};
  }

  ${media.xs} {
  }
`;

export const contentInput = css`
  ${commonInputStyle}
  min-height: 170px;
`;

export const headlineInput = css`
  ${commonInputStyle}
  height: 67px;
  resize: none;
  overflow: hidden;
`;

export const singlelineInput = css`
  ${commonInputStyle}
  height: 40px;
`;

export const selectorsContainer = css`
  display: block;
  margin-bottom: 6px;

  ${media.xs} {
    display: flex;
    justify-content: space-between;
  }
`;

export const selectContainer = css`
  position: relative;

  select::-ms-expand {
    display: none;
  }

  select::-ms-value {
    background: transparent;
  }
`;

export const selectArrow = css`
  position: absolute;
  top: 23px;
  right: 14px;
  width: 16px;
  height: 17px;
  z-index: -1;

  ${media.xs} {
    top: 12px;
  }
`;

export const selectButton = css`
  cursor: pointer;
  position: relative;
  background-color: transparent;
  border: solid 1px ${gray5};
  outline: none;
  width: calc(100% + 2px);
  font-size: 14px;
  color: ${charcoal};
  height: 40px;
  padding: 7px 16px;
  margin: 12px 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  &:focus {
    outline: 0 !important;
  }

  &:hover {
    border-color: ${charcoal} !important;
  }

  ${media.xs} {
    width: 200px;
    font-size: 16px;
    margin: 0;
    padding-top: 4px;
  }
`;

export const selectOption = css`
  outline: none;
  border: 0;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    color: red;
    box-shadow: 0 0 10px 100px ${blue9} inset;
  }
`;

export const starIcon = css`
  width: 32px;
  height: 32px;
  padding-right: 16px;
  box-sizing: content-box;
  cursor: pointer;

  path {
    stroke: ${gray7};
    stroke-width: 0.5;
    fill: ${white};
  }
`;

export const starIconShine = css`
  ${starIcon} path {
    fill: ${red9};
    stroke-width: 0;
  }
`;

export const rateLabelContainer = css`
  margin-top: 9px;
  height: 32px;
  position: absolute;
  display: inline-block;
`;

export const rateLabel = css`
  color: ${gray7};
  font-size: 16px;
`;

export const radioInput = css`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

export const checkboxArea = css`
  display: inline-block;
  position: relative;
  padding-left: 22px;
  width: 100%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

export const checkboxTextElement = css`
  padding-left: 10px;
  font-size: 16px;

  color: ${charcoal};

  & a {
    font-weight: ${bold};
    font-size: 14px;
  }
`;

export const checkbox = css`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

export const checkmark = css`
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: ${white};
  border: solid 2px ${gray7};
  border-image: initial;
`;

export const checkmarkChecked = css`
  ${checkmark}
  background-color: ${blue9};
  border-style: none;

  &::after {
    content: '';
    position: absolute;
    display: block;
    left: 8px;
    top: 3px;
    width: 5px;
    height: 11px;
    transform: rotate(45deg);
    border-style: solid;
    border-color: ${white};
    border-width: 0 2px 2px 0;
  }
`;

export const infoText = css`
  font-size: 16px;

  color: ${charcoal};
  margin-bottom: 12px;

  & a {
    font-weight: ${bold};
    font-size: 14px;
  }
`;

export const invalidFieldText = css`
  font-size: 16px;

  color: ${red9};
`;

export const submitButtonContainer = css`
  width: 100%;
  margin: 0 auto;
  margin-top: 48px;
  margin-bottom: 76px;

  ${media.xs} {
    margin-top: 32px;
    margin-bottom: 76px;
  }
`;

export const step2paragraph = css`
  ${infoText}
  margin-top: 20px;
  margin-bottom: 16px;
`;

export const step3urlContainer = css`
  ${infoText}
  text-align: center;
  margin-top: 36px;
  margin-bottom: 32px;
`;

export const accordionArrowDown = css`
  &::after {
    transform: rotate(45deg);
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-left: 18px;
    margin-bottom: 3px;
    border-bottom: 2px solid ${gray7};
    border-right: 2px solid ${gray7};
  }
`;

export const accordionArrowUp = css`
  ${accordionArrowDown}
  &::after {
    transform: rotate(-135deg);
    margin-bottom: -5px;
  }
`;

export const accordionContainer = css`
  margin-bottom: 32px;
  ul {
    ${infoText}
    list-style: disc;
    padding-left: 36px;
    margin-top: 8px;
  }
`;

export const accordionTitle = css`
  width: 100%;
  cursor: pointer;
  font-size: 18px;

  outline: none;
  user-select: none;
  font-weight: ${bold};
  text-decoration: underline;
  margin-bottom: 24px;
`;
